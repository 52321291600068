@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */


.carousel-cell{
    width: 100% !important;  
}
.main-carousel{
    font-size: 0.9rem !important;
    padding: 0 5%
}
.isotope{
    width: 100% !important;
}
.isotope-item1, .isotope-item2, .isotope-item3, .isotope-item4 { 
    width: calc(30% - 10px);
    font-size: .9rem;
    text-align: center;
}

.isotope-item1, .isotope-item3 {
    height: 100%;
    overflow: hidden;
}
.isotope-item4{
    /* width: 45%;
    height: 45%; */
    width: calc(40% - 10px);
    height: calc(50% - 10px);
}

.isotope-item2{
    width: calc(40% - 10px);
    height: 50%
}




/* no circle */
.flickity-button {
    background: transparent;
  }
  /* big previous & next buttons */
  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
  }
  /* icon color */
  .flickity-button-icon {
    /* fill: white; */
  }
  /* hide disabled button */
  .flickity-button:disabled {
    display: none;
  }




  .quote{}
  .title{
      font-weight: bold;
  }
  .overlay{
      padding: 5%;
  }

@screen md{

}
@screen lg {
    .carousel-cell{
        width: 50% !important;  
    }
    .flickity-prev-next-button {
        width: 75px;
        height: 75px;
      }
    
}
